<template>
  <RevSkipToContent
    v-if="!isUS"
    to="#skip-to-content-wrapper"
    to-anchor-id="#main-content"
  >
    {{ i18n(translations.skipToContent) }}
  </RevSkipToContent>
  <main id="main-content">
    <slot />
  </main>
</template>

<script lang="ts" setup>
import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevSkipToContent } from '@ds/components/SkipToContent'

import translations from './TheMainContent.translations'

const { market } = useMarketplace()
const isUS = market.countryCode === MarketCountryCode.US
const i18n = useI18n()
</script>
