<template>
  <div>
    <SkinnyBanner data-test="home-skinny-banner" page="home" />
    <!-- Short term solution to have a H1 for SEO and A11y purpose on the home page -->
    <!-- Todo: design a homepage element to include the H1 -->
    <!-- see [LIF-1601](https://backmarket.atlassian.net/browse/LIF-1601) -->
    <h1 class="sr-only">{{ homeData.title }}</h1>
    <meta :content="COMPANY_NAME" itemprop="name" />
    <meta :content="homeData.meta.image.src" itemprop="logo" />
    <TheMainContent>
      <div class="min-h-[100vh]">
        <!-- We display no placeholder component -->
        <!-- The content, style and order of these block can change at any moment -->
        <!-- Set a min height of viewport height, to avoid a flash of footer display -->

        <Container
          v-for="block in blocksToRender"
          :key="block.id"
          :width="getBlockWidth(block.type)"
        >
          <BlockRenderer
            :id="block.id"
            :block-position="block.blockPosition"
            :data-test="block.id"
            :hidden-devices="block.hiddenDevices"
            :props="block.props"
            :type="block.type"
          />
        </Container>
      </div>
    </TheMainContent>
    <RevContainer v-if="navigationData">
      <HomeNavigation :navigation-seo="navigationData" />
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { useHead, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { getHomeNavigationContent } from '@backmarket/http-api/src/api-specs-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import TheMainContent from '~/components/TheMainContent/TheMainContent.vue'
import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'

import { useCMSAlternateLinks } from '../cms/composables/useCMSAlternateLinks'
import { useCMSMeta } from '../cms/composables/useCMSMeta'
import { useFetchContent } from '../cms/composables/useFetchPageContent'
import { getBlockWidth, isFullWidth } from '../cms/helpers/block-width.helper'
import { createSocialMediaTagsFor } from '../cms/helpers/meta/meta'
import BlockRenderer from '../cms/shared-components/BlockRenderer/BlockRenderer.vue'
import Container from '../cms/shared-components/Container.vue'
import { useAddCanonicalLink } from '../seo/composables/useAddCanonicalLink'

import translations from './Home.translations'
import HomeNavigation from './HomeNavigation/HomeNavigation.vue'
import { assertsHomeDataExists } from './utils/assertsHomeDataExists'
import { useHomeSchema } from './utils/composables/useHomeSchema'
import { useHomeSocialMeta } from './utils/composables/useHomeSocialMeta'
import { getHtmlAttrsByCountry } from './utils/getHtmlAttrsByCountry'

const { FF_ENABLE_CONTENT_SERVICE_ON_HP } = useRuntimeConfig().public

const { data: homeData, error: homeError } = await useFetchContent(
  FF_ENABLE_CONTENT_SERVICE_ON_HP,
)
const { data: navigationData } = await useHttpFetch(getHomeNavigationContent)

assertsHomeDataExists(homeData, homeError)

const blocksToRender = computed(() =>
  homeData.value.blocks.map((block, index) => ({
    id: block.id,
    isFullWidth: isFullWidth(block.type),
    props: block.props,
    hiddenDevices: block.hiddenDevices,
    blockPosition: index + 1,
    type: block.type,
  })),
)

const {
  market: { countryCode },
} = useMarketplace()

const meta = useCMSMeta(homeData)
const i18n = useI18n()
const runtimeConfig = useRuntimeConfig()
const { COMPANY_NAME } = runtimeConfig.public
const alternateLinks = useCMSAlternateLinks(homeData)
const script = useHomeSchema(COMPANY_NAME)
const socials = useHomeSocialMeta()

useHead({
  title: homeData.value.titleSeo,
  titleTemplate: i18n(translations.metaTitleTemplate),
  htmlAttrs: getHtmlAttrsByCountry(countryCode),
  link: alternateLinks,
  meta: [...meta, ...createSocialMediaTagsFor(socials)],
  script,
})

useAddCanonicalLink()
</script>
